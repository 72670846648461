import React, { useState, useEffect } from "react";
import axios from "axios";
import { Layout, Table, Form, Input, Button, message, Space, Modal, Checkbox, Select } from "antd";
import { useAuth } from "../../Context/AuthContext";
import HeaderMenu from "../HeaderMenu/HeaderMenu";

const {  Content } = Layout;
const { Option } = Select;

const APIURL = 'https://api.aurora-connect.com/';
const apiCreds = process.env.REACT_APP_API_KEY;

const LinksComponent = () => {
  const { user, token, logout } = useAuth();
  const [linksData, setLinksData] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newBrand, setNewBrand] = useState({ brandName: '', frontURL: '', backURL: '', tradeURL: '', project: 1 });
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteCheckbox, setDeleteCheckbox] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const fetchLinks = async () => {
    try {
      const authString = `Basic ${btoa(apiCreds)}`;

      const response = await axios.get(`${APIURL}api/links`, {
        headers: {
          Authorization: authString,
        },
      });

      if (Array.isArray(response.data) && response.data.length > 0) {
        const updatedLinksData = response.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        setLinksData(updatedLinksData);
      } else {
        console.error("Некорректный формат ответа:", response.data);
      }
    } catch (error) {
      console.error("Ошибка при получении ссылок:", error);
    }
  };

  const handleEditClick = (id) => {
    setEditingId(id);
  };

  const handleInputChange = (e, type) => {
    const updatedData = linksData.map((item) => {
      if (item.id === editingId) {
        return { ...item, [type]: e.target.value.split(',').map(item => item.trim()) };
      }
      return item;
    });
    setLinksData(updatedData);
  };

  const handleCancelEditing = () => {
    setEditingId(null);
    fetchLinks();
  };

  const handleSaveClick = async () => {
    try {
      const updatedLink = linksData.find((item) => item.id === editingId);

      if (!updatedLink.id) {
        console.error("Missing id for link:", updatedLink);
        return;
      }

      const { isEditing, ...updatedLinkWithoutEditing } = updatedLink;
      const authString = `Basic ${btoa(apiCreds)}`;

      await axios.put(`${APIURL}api/links/${updatedLink.id}`, updatedLinkWithoutEditing, {
        headers: {
          Authorization: authString,
        },
      });

      message.success("Links updated successfully!");
      fetchLinks();
      setEditingId(null);
    } catch (error) {
      console.error("Error updating links:", error);
      message.error("Error updating links. Please check the server logs.");
    }
  };

  const handleAddNewBrand = () => {
    setIsModalVisible(true);
  };

  const handleNewBrandInputChange = (e) => {
    const { name, value } = e.target;
    setNewBrand({ ...newBrand, [name]: value });
  };

  const handleNewBrandProjectChange = (value) => {
    setNewBrand({ ...newBrand, project: value });
  };

  const handleSaveNewBrand = async () => {
    try {
      const authString = `Basic ${btoa(apiCreds)}`;

      await axios.post(`${APIURL}api/links`, newBrand, {
        headers: {
          Authorization: authString,
        },
      });

      message.success("New brand added successfully!");
      setIsModalVisible(false);
      setNewBrand({ brandName: '', frontURL: '', backURL: '', tradeURL: '', project: 1 });
      fetchLinks();
    } catch (error) {
      console.error("Error adding new brand:", error);
      message.error("Error adding new brand. Please check the server logs.");
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setDeleteModalVisible(true);
  };

  const handleDeleteCheckboxChange = (e) => {
    setDeleteCheckbox(e.target.checked);
  };

  const handleDeleteConfirm = async () => {
    try {
      const authString = `Basic ${btoa(apiCreds)}`;

      await axios.delete(`${APIURL}api/links/${deleteId}`, {
        headers: {
          Authorization: authString,
        },
      });

      message.success("Brand deleted successfully!");
      setDeleteModalVisible(false);
      setDeleteCheckbox(false);
      setDeleteId(null);
      fetchLinks();
    } catch (error) {
      console.error("Error deleting brand:", error);
      message.error("Error deleting brand. Please check the server logs.");
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setDeleteCheckbox(false);
    setDeleteId(null);
  };

  useEffect(() => {
    fetchLinks();
  }, [token]);

  const columns = [
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      key: 'brandName',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Brand Name"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.brandName.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Front URL',
      dataIndex: 'frontURL',
      key: 'frontURL',
      render: (urls) => urls.join(', '),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Front URL"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.frontURL.some(url => url.toLowerCase().includes(value.toLowerCase())),
    },
    {
      title: 'Back URL',
      dataIndex: 'backURL',
      key: 'backURL',
      render: (urls) => urls.join(', '),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Back URL"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.backURL.some(url => url.toLowerCase().includes(value.toLowerCase())),
    },
    {
      title: 'Trade URL',
      dataIndex: 'tradeURL',
      key: 'tradeURL',
      render: (urls) => urls.join(', '),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Trade URL"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.tradeURL.some(url => url.toLowerCase().includes(value.toLowerCase())),
    },
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Project"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => String(record.project).toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEditClick(record.id)}>
            Edit
          </Button>
          <Button type="primary" danger ghost onClick={() => handleDeleteClick(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
        <HeaderMenu user={user} logout={logout} />
      <Content style={{ padding: '0 50px', marginTop: '64px' }}>
        <div className="site-layout-content">
          {user ? (
            <>
              <h1>Links</h1>
              <Button type="primary" onClick={handleAddNewBrand} style={{ marginBottom: '20px' }}>
                Add New Brand
              </Button>
              {editingId ? (
                <Form layout="vertical" onFinish={handleSaveClick}>
                  <Form.Item
                    label="Front URL"
                    name="frontURL"
                    initialValue={linksData.find(item => item.id === editingId)?.frontURL.join(', ')}
                  >
                    <Input
                      placeholder="Enter front URLs"
                      onChange={(e) => handleInputChange(e, "frontURL")}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Back URL"
                    name="backURL"
                    initialValue={linksData.find(item => item.id === editingId)?.backURL.join(', ')}
                  >
                    <Input
                      placeholder="Enter back URLs"
                      onChange={(e) => handleInputChange(e, "backURL")}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Trade URL"
                    name="tradeURL"
                    initialValue={linksData.find(item => item.id === editingId)?.tradeURL.join(', ')}
                  >
                    <Input
                      placeholder="Enter trade URLs"
                      onChange={(e) => handleInputChange(e, "tradeURL")}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save Changes
                    </Button>
                    <Button type="default" onClick={handleCancelEditing} style={{ marginLeft: '8px' }}>
                      Cancel Editing
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Table columns={columns} dataSource={linksData} rowKey="id" />
              )}
              <Modal
                title="Add New Brand"
                visible={isModalVisible}
                onOk={handleSaveNewBrand}
                onCancel={() => setIsModalVisible(false)}
              >
                <Form layout="vertical">
                  <Form.Item label="Brand Name">
                    <Input
                      name="brandName"
                      value={newBrand.brandName}
                      onChange={handleNewBrandInputChange}
                    />
                  </Form.Item>
                  <Form.Item label="Front URL">
                    <Input
                      name="frontURL"
                      value={newBrand.frontURL}
                      onChange={handleNewBrandInputChange}
                    />
                  </Form.Item>
                  <Form.Item label="Back URL">
                    <Input
                      name="backURL"
                      value={newBrand.backURL}
                      onChange={handleNewBrandInputChange}
                    />
                  </Form.Item>
                  <Form.Item label="Trade URL">
                    <Input
                      name="tradeURL"
                      value={newBrand.tradeURL}
                      onChange={handleNewBrandInputChange}
                    />
                  </Form.Item>
                  <Form.Item label="Project">
                    <Select
                      name="project"
                      value={newBrand.project}
                      onChange={handleNewBrandProjectChange}
                      style={{ width: '100%' }}
                    >
                      <Option value={1}>Проект 1</Option>
                      <Option value={2}>Проект 2</Option>
                      <Option value={3}>Проект 3</Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Modal>
              <Modal
                title="Confirm Deletion"
                visible={deleteModalVisible}
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                okButtonProps={{ disabled: !deleteCheckbox }}
              >
                <Checkbox onChange={handleDeleteCheckboxChange}>
                  I confirm that I want to delete this brand.
                </Checkbox>
              </Modal>
            </>
          ) : (
            message.warning('Please log in to view and edit links')
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default LinksComponent;
