import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import { Layout, Select, Button, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import HeaderMenu from "../HeaderMenu/HeaderMenu";

const {  Content } = Layout;
const { Option } = Select;

const APIURL = "https://api.aurora-connect.com/";
const apiCreds = process.env.REACT_APP_API_KEY;

const PurgeCache = () => {
	const [brands, setBrands] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState("");
	const [domains, setDomains] = useState([]);
	const [selectedDomain, setSelectedDomain] = useState("");
	const [loadingBrands, setLoadingBrands] = useState(false);
	const [loadingDomains, setLoadingDomains] = useState(false);
	const [loading, setLoading] = useState(false);
	const [project, setProject] = useState(1); // Default project
	const { user, logout } = useAuth();

	useEffect(() => {
		if (project) {
			loadBrands(project);
		}
	}, [project]);

	const loadBrands = async (project) => {
		try {
			setLoadingBrands(true);

			const authString = `Basic ${btoa(apiCreds)}`;
			const response = await axios.get(`${APIURL}brands?project=${project}`, {
				headers: {
					Authorization: authString,
				},
			});

			if (response.status === 200) {
				const data = response.data;
				setBrands(data.brands);
				setSelectedBrand("");
			} else {
				console.error(`Error loading brands. Status: ${response.status}`);
				const errorText = response.data
					? response.data.message
					: "Unknown error";
				console.error(`Error details: ${errorText}`);
			}
		} catch (error) {
			console.error("Unexpected error loading brands:", error);
		} finally {
			setLoadingBrands(false);
		}
	};

	const loadDomains = async (selectedBrand) => {
		if (!selectedBrand) {
			return;
		}

		try {
			setLoadingDomains(true);

			const authString = `Basic ${btoa(apiCreds)}`;
			const response = await axios.get(
				`${APIURL}domains/${encodeURIComponent(selectedBrand)}/${project}`,
				{
					headers: {
						Authorization: authString,
					},
				}
			);

			if (response.status === 200) {
				const data = response.data;

				if (!data || !data.domains || !Array.isArray(data.domains)) {
					console.error("Error loading domains: Invalid response format");
					return;
				}

				setDomains(data.domains);
				setSelectedDomain("");
			} else {
				console.error(`Error loading domains. Status: ${response.status}`);
				const errorText = response.data
					? response.data.message
					: "Unknown error";
				console.error(`Error details: ${errorText}`);
			}
		} catch (error) {
			console.error("Error loading domains:", error);
		} finally {
			setLoadingDomains(false);
		}
	};

	const getZoneIdForDomain = async (authString, selectedBrand, project, domain) => {
		try {
			const response = await axios.get(
				`${APIURL}zone-id/${encodeURIComponent(
					selectedBrand
				)}/${project}/${encodeURIComponent(domain)}`,
				{
					headers: {
						Authorization: authString,
					},
				}
			);

			if (response.status === 200) {
				const data = response.data;
				return data.zoneId || null;
			} else {
				console.error(
					`Error getting Zone ID for ${domain}. Status: ${response.status}`
				);
				const errorText = response.data
					? response.data.message
					: "Unknown error";
				console.error(`Error details: ${errorText}`);
				return null;
			}
		} catch (error) {
			console.error(`Error getting Zone ID for ${domain}:`, error);
			return null;
		}
	};

	const purgeCache = async () => {
		if (loading || !selectedBrand || !selectedDomain) {
			return;
		}

		setLoading(true);

		try {
			const zoneId = await getZoneIdForDomain(
				`Basic ${btoa(apiCreds)}`,
				selectedBrand,
				project,
				selectedDomain
			);

			if (!zoneId) {
				message.error("Zone ID не найден.");
				setLoading(false);
				return;
			}

			const authString = `Basic ${btoa(apiCreds)}`;
			const purgeResponse = await axios.post(
				`${APIURL}purge-cache`,
				{
					zoneId,
					brandName: selectedBrand,
					project,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: authString,
					},
				}
			);

			if (purgeResponse.status === 200) {
				const purgeData = purgeResponse.data;
				message.success(purgeData.message);
			} else {
				const purgeErrorData = purgeResponse.data;
				console.error("Ошибка при сбросе кэша:", purgeErrorData.message);
				message.error(
					"Ошибка при сбросе кэша. Пожалуйста, проверьте логи сервера."
				);
			}
		} catch (error) {
			console.error("Ошибка при сбросе кэша:", error);
			message.error(
				"Ошибка при сбросе кэша. Пожалуйста, проверьте логи сервера."
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Layout style={{ minHeight: "100vh" }}>

				<HeaderMenu user={user} logout={logout} />

			<Content style={{ padding: "0 50px", marginTop: "64px" }}>
				<div className="site-layout-content">
					{user ? (
						<>
							<h1>Purge Cache</h1>
							<div className="mb-4">
								<label htmlFor="projectSelect">Выберите проект:</label>
								<Select
									id="projectSelect"
									onChange={(value) => setProject(value)}
									value={project}
									style={{ width: "100%" }}
								>
									<Option value={1}>Проект 1</Option>
									<Option value={2}>Проект 2</Option>
									<Option value={3}>Проект 3</Option>
								</Select>
							</div>
							<div className="mb-4">
								<label htmlFor="brandNameSelect">Выберите бренд:</label>
								<Select
									id="brandNameSelect"
									className="form-control"
									showSearch
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									onChange={(value) => {
										setSelectedBrand(value);
										loadDomains(value);
									}}
									value={selectedBrand}
									disabled={loadingBrands}
									style={{ width: "100%" }}
								>
									<Option value="" disabled>
										{loadingBrands ? "Загрузка брендов..." : "Выберите бренд"}
									</Option>
									{brands.map((brand) => (
										<Option key={brand} value={brand}>
											{brand}
										</Option>
									))}
								</Select>
							</div>
							<div className="mb-4">
								<label htmlFor="domainSelect">Выберите домен:</label>
								<Select
									id="domainSelect"
									className="form-control"
									showSearch
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									onChange={(value) => setSelectedDomain(value)}
									value={selectedDomain}
									disabled={loadingDomains || domains.length === 0}
									style={{ width: "100%" }}
								>
									<Option value="" disabled>
										{loadingDomains
											? "Загрузка доменов..."
											: domains.length === 0
											? "Нет доступных доменов"
											: "Выберите домен"}
									</Option>
									{domains
										.filter((item) => item !== null)
										.map((domain) => (
											<Option key={domain} value={domain}>
												{domain}
											</Option>
										))}
								</Select>
							</div>
							<Button
								type="primary"
								onClick={purgeCache}
								disabled={loading}
								style={{ width: "100%" }}
							>
								{loading ? (
									<Spin indicator={<LoadingOutlined spin />} />
								) : (
									"Очистить кэш"
								)}
							</Button>
						</>
					) : (
						<Link to="/signin" className="btn btn-primary">
							Войти
						</Link>
					)}
				</div>
			</Content>
		</Layout>
	);
};

export default PurgeCache;
