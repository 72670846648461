import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'; 
import { useAuth } from './Context/AuthContext';
import { Form, Input, Button, Card, Typography, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons'; 

const { Title, Text } = Typography;

const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login, user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [user, history]);

  const handleSignIn = async () => {
    try {
      await login(username, password);
      history.push('/');
    } catch (error) {
      setError('Неверные учетные данные');
      message.error('Неверные учетные данные');
    }
  };

  return !user ? (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6 col-lg-4">
          <Card>
            <Title level={2} className="text-center mb-4">Вход</Title>
            {error && <Text type="danger" className="text-center">{error}</Text>}
            <Form layout="vertical" onFinish={handleSignIn}>
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Пожалуйста, введите имя пользователя' }]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Имя пользователя"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Пароль"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-100">
                  Войти
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  ) : null;
};

export default SignIn;
