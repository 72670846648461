import React, { useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import { Layout, Form, Input, Button, Spin, Select, message } from 'antd';
import axios from 'axios';

const {  Content } = Layout;
const { Option } = Select;

const APIURL = 'https://api.aurora-connect.com/';
const apiCreds = process.env.REACT_APP_API_KEY;

const DNSUpdate = () => {
  const [oldIp, setOldIp] = useState('');
  const [newIp, setNewIp] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [project, setProject] = useState(1); // Default project
  const { user, logout } = useAuth();

  const updateDNS = async () => {
    setLoading(true);
    setCompleted(false);
    setResult('');

    try {
      const authString = `Basic ${btoa(apiCreds)}`;

      const response = await axios.post(
        `${APIURL}update-dns`,
        { oldIp, newIp, project },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authString,
          },
        }
      );

      console.log('Response data:', response.data); // Логирование данных ответа

      if (response.status === 200) {
        const data = response.data;
        displayResults(data);
      } else {
        console.error('Failed to update DNS:', response.statusText);
        displayError('Failed to update DNS. Please check the server logs.');
      }
    } catch (error) {
      console.error('Error updating DNS:', error);
      displayError('Error updating DNS. Please check the server logs.');
    } finally {
      setLoading(false);
      setCompleted(true);
    }
  };

  const displayResults = (data) => {
    setResult(
      <div>
        <p>{data.message}</p>
        {data.results && data.results.length > 0 && (
          <ul>
            {data.results.map((result) => (
              <li key={result.brandName}>
                {result.brandName}: {result.message}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const displayError = (message) => {
    setResult(<p style={{ color: 'red' }}>{message}</p>);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>

        <HeaderMenu user={user} logout={logout} />

      <Content style={{ padding: '0 50px', marginTop: '64px' }}>
        <div className="site-layout-content">
          {user ? (
            <>
              <h1>Cloudflare DNS Updater</h1>
              <Form layout="vertical" onFinish={updateDNS}>
              <Form.Item
                  label="Выберите проект"
                  name="project"
                  rules={[{ required: true, message: 'Please select a project' }]}
                >
                  <Select
                    id="projectSelect"
                    onChange={(value) => setProject(value)}
                    value={project}
                    style={{ width: '100%' }}
                  >
                    <Option value={1}>Проект 1</Option>
                    <Option value={2}>Проект 2</Option>
                    <Option value={3}>Проект 3</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Old IP"
                  name="oldIp"
                  rules={[{ required: true, message: 'Please enter the old IP' }]}
                >
                  <Input
                    placeholder="Enter old IP"
                    value={oldIp}
                    onChange={(e) => setOldIp(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="New IP"
                  name="newIp"
                  rules={[{ required: true, message: 'Please enter the new IP' }]}
                >
                  <Input
                    placeholder="Enter new IP"
                    value={newIp}
                    onChange={(e) => setNewIp(e.target.value)}
                  />
                </Form.Item>
              
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    {loading ? <Spin /> : 'Update DNS'}
                  </Button>
                </Form.Item>
              </Form>
              <div id="result">{result}</div>
              {completed && <p>Скрипт выполнил свою работу</p>}
            </>
          ) : (
            message.warning('Please log in to update DNS')
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default DNSUpdate;
