import React, { useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import { Layout, Form, Input, Button, Select, Spin, message } from 'antd';
import axios from 'axios';

const {  Content } = Layout;
const { Option } = Select;

const APIURL = 'https://api.aurora-connect.com/';
const apiCreds = process.env.REACT_APP_API_KEY;

const AddCloudflareAccount = () => {
  const [brandName, setBrandName] = useState('');
  const [email, setEmail] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [project, setProject] = useState(1); // Default project
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const { user, logout } = useAuth();

  const handleAddAccount = async () => {
    setLoading(true);
    setResult('');

    try {
      const authString = `Basic ${btoa(apiCreds)}`;

      const response = await axios.post(
        `${APIURL}add-cloudflare-account`,
        { brandName, email, apiKey, project },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authString,
          },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        const data = response.data;
        setResult(data.message);
        message.success(data.message);
      } else {
        const errorData = response.data;
        console.error('Failed to add account:', errorData.message);
        setResult('Failed to add account. Please check the server logs.');
        message.error('Failed to add account. Please check the server logs.');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error adding account:', error);
      setResult('Error adding account. Please check the server logs.');
      message.error('Error adding account. Please check the server logs.');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>

        <HeaderMenu user={user} logout={logout} />

      <Content style={{ padding: '0 50px', marginTop: '64px' }}>
        <div className="site-layout-content">
          {user ? (
            <>
              <h1>Add Cloudflare Account</h1>
              <Form layout="vertical" onFinish={handleAddAccount}>
                <Form.Item
                  label="Brand Name"
                  name="brandName"
                  rules={[{ required: true, message: 'Please enter the brand name' }]}
                >
                  <Input
                    placeholder="Enter brand name"
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please enter the email' }]}
                >
                  <Input
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="API Key"
                  name="apiKey"
                  rules={[{ required: true, message: 'Please enter the API key' }]}
                >
                  <Input
                    placeholder="Enter API key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Выберите проект"
                  name="project"
                  rules={[{ required: true, message: 'Please select a project' }]}
                >
                  <Select
                    id="projectSelect"
                    onChange={(value) => setProject(value)}
                    value={project}
                    style={{ width: '100%' }}
                  >
                    <Option value={1}>Проект 1</Option>
                    <Option value={2}>Проект 2</Option>
                    <Option value={3}>Проект 3</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    {loading ? <Spin /> : 'Add Account'}
                  </Button>
                </Form.Item>
              </Form>
              <div id="result">{result}</div>
            </>
          ) : (
            message.warning('Please log in to add Cloudflare account')
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default AddCloudflareAccount;
