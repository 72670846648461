import React, { useState, useEffect } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { Layout, Select, Input, Button, Row, Col, message, Spin } from 'antd';
import axios from 'axios';
import HeaderMenu from '../HeaderMenu/HeaderMenu';

const {  Content } = Layout;
const { Option } = Select;

const APIURL = 'https://api.aurora-connect.com/';
const apiCreds = process.env.REACT_APP_API_KEY;

const AddFrontDomain = () => {
  const { user, logout } = useAuth();
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [newDomain, setNewDomain] = useState('');
  const [records, setRecords] = useState([
    { type: 'A', name: '@', content: '5.42.92.193', proxied: true },
    { type: 'A', name: 'www', content: '5.42.92.193', proxied: true },
    { type: 'A', name: 'pop', content: '5.42.92.194' },
    { type: 'A', name: 'agent', content: '5.42.92.195', proxied: true },
    { type: 'A', name: 'mail', content: '5.42.92.196' },
    { type: 'MX', name: '@', content: 'mail.domainname.com', priority: 10 },
    { type: 'MX', name: '@', content: 'mail2.domainname.com', priority: 20 },
    { type: 'TXT', name: 'dkim._domainkey', content: 'v=DKIM1; k=rsa; p=yourPublicKeyHere' },
    { type: 'TXT', name: '_dmarc', content: 'v=DMARC1; p=reject; rua=mailto:dmarc@domainname.com; ruf=mailto:dmarc@domainname.com; fo=1; adkim=r; aspf=r;' },
    { type: 'TXT', name: '@', content: 'v=spf1 ip4:5.42.92.193 a mx ~all' },
    { type: 'A', name: 'smtp', content: '5.42.92.197' },
    { type: 'A', name: 'www.smtp', content: '5.42.92.198' },
  ]);

  const [project, setProject] = useState(1); // Default project
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (project) {
      fetchBrands(project);
    }
  }, [project]);

  const fetchBrands = async (project) => {
    try {
      const response = await axios.get(`${APIURL}brands?project=${project}`, {
        headers: {
          Authorization: `Basic ${btoa(apiCreds)}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setBrands(data.brands);
        setSelectedBrand(''); // Reset selected brand when project changes
      } else {
        console.error('Error fetching brands:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const handleAddDomain = async () => {
    try {
      if (!selectedBrand || !newDomain || !records.every((record) => record.type && record.name && record.content)) {
        message.error('Выберите бренд, введите новый домен и заполните все записи');
        return;
      }

      if (!apiCreds) {
        message.error('API ключ не настроен. Пожалуйста, проверьте настройки переменных окружения.');
        return;
      }

      setLoading(true);

      // Проверяем каждую запись и устанавливаем значение proxied, если оно отсутствует
      const updatedRecords = records.map((record) => ({
        ...record,
        proxied: typeof record.proxied === 'boolean' ? record.proxied : false,
      }));

      const authString = `Basic ${btoa(apiCreds)}`;

      const response = await axios.post(
        `${APIURL}add-front-domain`,
        { selectedBrand, newDomain, records: updatedRecords, project },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authString,
          }
        }
      );

      setLoading(false);

      if (response.status >= 200 && response.status < 300) {
        const data = response.data;
        setResult(data.message);
        message.success(data.message);
      } else {
        const errorData = response.data;
        console.error('Failed to add domain:', errorData.message);
        setResult('Failed to add domain. Please check the server logs.');
        message.error('Failed to add domain. Please check the server logs.');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error adding domain:', error.response ? error.response.data : error.message);
      setResult('Error adding domain. Please check the server logs.');
      message.error('Error adding domain. Please check the server logs.');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>

        <HeaderMenu user={user} logout={logout} />

      <Content style={{ padding: '0 50px', marginTop: '64px' }}>
        {user ? (
          <Spin spinning={loading}>
            <div style={{ margin: '20px 0' }}>
              <h1>Add Front Domain</h1>
              <div>
                <label htmlFor="projectSelect">Выберите проект:</label>
                <Select
                  id="projectSelect"
                  onChange={(value) => setProject(value)}
                  value={project}
                  style={{ width: '100%' }}
                >
                  <Option value={1}>Проект 1</Option>
                  <Option value={2}>Проект 2</Option>
                  <Option value={3}>Проект 3</Option>
                </Select>
              </div>
              <div className="mt-3">
                <label htmlFor="brandSelect">Выберите бренд:</label>
                <Select
                  id="brandSelect"
                  onChange={(value) => setSelectedBrand(value)}
                  value={selectedBrand}
                  style={{ width: '100%' }}
                >
                  <Option value="" disabled>
                    Выберите бренд
                  </Option>
                  {brands.map((brand) => (
                    <Option key={brand} value={brand}>
                      {brand}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="mt-3">
                <label htmlFor="newDomain">Введите новый домен:</label>
                <Input
                  type="text"
                  id="newDomain"
                  value={newDomain}
                  onChange={(e) => setNewDomain(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="mt-3">
                <label htmlFor="records">Введите записи:</label>
                {records.map((record, index) => (
                  <Row key={index} gutter={16} className="mt-2">
                    <Col span={12}>
                      <Input
                        placeholder="Имя записи"
                        value={record.name}
                        onChange={(e) => {
                          const updatedRecords = [...records];
                          updatedRecords[index].name = e.target.value;
                          setRecords(updatedRecords);
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        placeholder="Контент записи"
                        value={record.content}
                        onChange={(e) => {
                          const updatedRecords = [...records];
                          updatedRecords[index].content = e.target.value;
                          setRecords(updatedRecords);
                        }}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
              <Button
                type="primary"
                className="mt-3"
                onClick={handleAddDomain}
              >
                Добавить домен
              </Button>
              {result && (
                <div
                  className={`mt-3 ${
                    result.includes('успешно') ? 'text-success' : 'text-danger'
                  }`}
                >
                  {result}
                </div>
              )}
            </div>
          </Spin>
        ) : (
          <Link to="/signin" className="btn btn-primary">
            Войти
          </Link>
        )}
      </Content>
    </Layout>
  );
};

export default AddFrontDomain;
