import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './Home';
import PurgeCache from './Components/PurgeCache/PurgeCache';
import DNSUpdate from './Components/DNSUpdate/DNSUpdate';
import AddDomain from './Components/AddDomain/AddDomain';
import AddBackDomain from './Components/AddBackDomain/AddBackDomain';
import AddFrontDomain from './Components/AddFrontDomain/AddFrontDomain';
import LinksComponent from './Components/LinkComponent/LinkComponent';
import SignIn from './SignIn';
import MailServerChecker from './Components/MailServerChecker/MailServerChecker';
import AddCloudflareAccount from './Components/AddCloudflareAccount/AddCloudflareAccount';
import TLS from './Components/TLS/TLS';

import { AuthProvider, useAuth } from './Context/AuthContext';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/purge" component={PurgeCache} />
          <PrivateRoute path="/dns-update" component={DNSUpdate} />
          <PrivateRoute path="/add-domain" component={AddDomain} />
          <PrivateRoute path="/add-back" component={AddBackDomain} />
          <PrivateRoute path="/add-front" component={AddFrontDomain} />
          <PrivateRoute path="/tls" component={TLS} />
          <Route path="/signin" component={SignIn} /> 
          <PrivateRoute path="/links" component={LinksComponent} /> 
          <PrivateRoute path="/smtp" component={MailServerChecker} /> 
          <PrivateRoute path="/add-cloud" component={AddCloudflareAccount} /> 
        </Switch>
      </Router>
    </AuthProvider>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

export default App;
